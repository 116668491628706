require("./bootstrap");

//require("alpinejs");
//import "alpinejs";
import Alpine from 'alpinejs'

window.Alpine = Alpine

Alpine.start();
import "daisyui/dist/base"
import "daisyui/dist/styled"
import $ from 'jquery';
window.$ = window.jQuery = $;
/*
import Alpine from 'alpinejs'

window.Alpine = Alpine

Alpine.start()*/

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import TomSelect from "tom-select";
import 'tom-select/dist/esm/plugins/remove_button/plugin';
// CommonJS
window.Swal = require('sweetalert2')

import 'jszip';
import 'datatables.net-dt';
import 'datatables.net-buttons-dt'
import 'datatables.net-buttons/js/buttons.print'
import 'datatables.net-buttons/js/buttons.html5'
import 'date-holidays'

const flatpickrDefault = require("flatpickr").default;
const flatpickrDelivered = require("flatpickr").default;
const flatpickrNextDelivered = require("flatpickr").default;
const German = require("flatpickr/dist/l10n/de.js").default.de;
import flatpickr from "flatpickr";

flatpickrDefault("#date-time-picker", {
    enableTime: true,
    dateFormat: "d.m.Y H:i",
    locale: German,
    weekNumbers: true,
    minDate: new Date().fp_incr(-7),
    maxDate: "today"
});

var Holidays = require('date-holidays')
var hd = new Holidays('DE','BW', {types:['public']});
//hd.holidays['Buß- und Bettag'] = false;

window.createFlatpickrDeliveryDay =function (htmlId, defaultDate ) {
    flatpickr(htmlId, {
        enableTime: false,
        dateFormat: "d.m.Y",
        locale: German,
        minDate: "today",
        defaultDate: defaultDate,
        weekNumbers: true,
        "disable": [
            function (date) {
                // return true to disable
                return (date.getDay() === 0 || hd.isHoliday(date) );

            }
        ],
        onDayCreate: function (dObj, dStr, fp, dayElem) {
            var date = dayElem.dateObj;
            if (date.getDay() === 6) {
                dayElem.className += ' saturday';
            }
        }
    });
}

window.sweetConfirm = function (title, message, callback) {
    new Swal({
        title: title,
        text: message,
        icon: "warning",
        dangerMode: true,
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: 'Abbrechen',
        confirmButtonText: ' Bestätigen'
    }).then((confirmed) => {
        callback(confirmed && confirmed.value === true);
    });
}

window.tomSelect = function (htmlId) {
    let config = {create: false,
    };
    new TomSelect('#' + htmlId,config);
}

window.createFlatpickr =function (htmlId, defaultDate ) {
    flatpickr(htmlId, {
        enableTime: true,
        dateFormat: "d.m.Y H:i",
        locale: German,
        defaultDate: defaultDate,
        weekNumbers: true,
    });
}

/*
window.toggleTheme = function () {
    console.log('foo');
    //document.documentElement.classList.add('dark');
}*/
